<template>
  <div v-if="!isIoOptsLoading" :class="[showIntermediateScreen ? 'intermediate-screen-bg' : '']">
    <IntermediateScreen
      v-if="showIntermediateScreen"
      :warning-message="warningMessage"
      :warning-action-button-title="warningActionButtonTitle"
      :page-title="pageTitle"
      :empty-message="emptyMessage"
      :page-action-button1-title="pageActionButton1Title"
      :page-action-button2-title="pageActionButton2Title"
      @warning-action-button-click="warningActionButtonClick"
      @page-action-button1-click="pageActionButton1Click"
      @page-action-button2-click="pageActionButton2Click"
    />
    <div v-else class="container trans-effect no-embed" :class="showFilters ? 'sideopen' : ''">
      <div class="top-area">
        <div class="top-area-right top-last">
          <div
            :class="isIncrementalReach ? 'disableFilterPanel' : ''"
            class="top-export-icon"
            @click="openCloseFilters"
          >
            <span class="intip4 tb-export" data-tool-tip="Open Filter Options">
              <font-awesome-icon :icon="['fal', 'cog']" />
              <span v-if="appliedFilterCount > 0" class="filter-cnt-bubble filter-cnt-badge">{{
                appliedFilterCount > 0 ? `${appliedFilterCount}` : ''
              }}</span>
            </span>
          </div>
          <b-radio-select-checkbox
            v-if="!isEventLoading"
            icon-pos="left"
            :options="filters.eventOptions || eventOptions"
            title=""
            header="Event"
            class="events-dd"
            :disable-drop-down-select-option="true"
            :enable-option-click-select="true"
            @selected-options="onSelectEvents"
          />

          <div class="icon-cal" :class="isEmbed ? 'no-right-mg' : ''">
            <b-datepicker
              :week1="[dates.startDate, dates.endDate]"
              :week2="[dates.compareStartDate, dates.compareEndDate]"
              :campaign-options="ioOptions"
              @change="bDatepickerChanges"
            />
            <div
              v-if="!isEmbed"
              class="top-export-icon downloadIcon"
              @click="toggleShowExportTable(!showExportTable)"
            >
              <span class="intip4 tb-export" data-tool-tip="Open Report Builder">
                <font-awesome-icon :icon="['fal', 'arrow-to-bottom']" />
              </span>
            </div>
          </div>
        </div>
      </div>
      <transition name="expand-table">
        <div v-if="showFilters" class="filter-panel-wrap" @click="onclickFilterSec">
          <div v-if="loadingFilters">
            <b-loading-spinner />
          </div>
          <div v-else>
            <div class="filter media-settings">
              <div class="filter-ttl">
                <span>MEDIA</span>
              </div>
              <div class="filter-group mediaFilters">
                <div>
                  <b-select-checkbox
                    :options="filters.ioOptions || ioOptions"
                    title=""
                    header="Campaign"
                    class="media-select"
                    component-id="ioOptionDropdown"
                    :show-all="filters.ioOptions.length > 1 || ioOptions.length > 1"
                    @selected-options="onSelectIO"
                  />
                </div>
                <b-select-checkbox
                  :options="filters.adGroupOptions || adGroupOptions"
                  title=""
                  header="Ad Group"
                  class="ad-group-select"
                  :disabled="isAdGroupLoading"
                  :show-all="filters.adGroupOptions.length > 1 || adGroupOptions.length > 1"
                  @selected-options="onSelectAdGroups"
                />
                <div class="fp-text-link view-pop-fltr">
                  <p @click="toggleFilterModal">Advanced Filters</p>
                  <span
                    >{{ appliedFilterCount > 0 ? `(${appliedFilterCount} Applied)` : '' }}
                    <font-awesome-icon
                      v-if="appliedFilterCount > 0"
                      :icon="['far', 'times']"
                      class="advance-filter-clear-icon"
                      @click="onClearAdvanceFilters"
                    />
                  </span>
                </div>
              </div>
            </div>
            <div class="filter method-settings">
              <div class="filter-ttl"><span>ATTRIBUTION SETTINGS</span></div>
              <div :class="`filter-group ${isVCRCategory ? 'readonly-db' : ''}`">
                <b-radio-tab-group
                  :initial-group="filters.totalOrUniqueRadioOptions || totalOrUniqueRadioOptions"
                  class="tot-uniq"
                  @update="updateTotalOrUniqueRadioOptions"
                />
              </div>
              <div class="filter-ttl"><span>Methodology</span></div>
              <div :class="`filter-group ${isVCRCategory ? 'readonly-db' : ''}`">
                <b-select
                  class="media-select"
                  :options="filters.methodologyOptions"
                  :selected="filters.methodology"
                  @change="onSelectMethodology"
                />
                <div class="conv-content">
                  <div class="filter-ttl"><span>Conversion Window</span></div>
                  <div class="conversion-opt-cont">
                    <div class="opt-b1" data-children-count="1">
                      <input
                        v-model="filters.conversionWindowDisplay"
                        type="text"
                        name="conversion-window"
                        class="flp-input input-conv"
                        @keypress="onlyNumber"
                      />
                    </div>
                    <div class="opt-b2">
                      <b-checkbox
                        label="Days"
                        name="Days"
                        icon="circle"
                        :is-radio="true"
                        :checked="filters.conversionWindowUnit === 'DAYS'"
                        @change="updateConversionWindowUnit"
                      />
                      <b-checkbox
                        label="Hours"
                        name="Hours"
                        icon="circle"
                        :is-radio="true"
                        :checked="filters.conversionWindowUnit === 'HOURS'"
                        @change="updateConversionWindowUnit"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <k-button :size="3" label="APPLY" @click="applyFilters" />
            <font-awesome-icon
              :icon="['far', 'times']"
              class="close-filters"
              @click="openCloseFilters"
            />
          </div>
        </div>
      </transition>
      <b-flip-modal
        v-if="showExportTable"
        hide-close-button
        enable-click-out-side
        @close="toggleShowExportTable(false)"
      >
        <export-table slot="modal-body" @close="toggleShowExportTable(false)" />
      </b-flip-modal>
      <div
        id="summaryDiv"
        class="mid-area"
        :class="[showLiftSummary ? 'showIncrBox' : 'showDefaultBox']"
      >
        <dash-summary-stats :window-type="WINDOW_TYPE_IMPRESSION" />
        <dash-summary-stats :window-type="WINDOW_TYPE_REACH" />
        <dash-summary-stats :window-type="WINDOW_TYPE_CONVERSION" :pixel="universalPixelId" />
        <dash-summary-stats
          v-if="showLiftSummary"
          :window-type="WINDOW_TYPE_LIFT"
          :pixel="universalPixelId"
        />
      </div>
      <div class="last-area">
        <div class="left-part">
          <performance-panel />
        </div>
        <graphs-panel class="right-part" />
      </div>
    </div>
    <div v-if="showAdvanceFilters" class="advance-filter-container">
      <AdvancedFiltersModal
        :is-ad-group-loading="isAdGroupLoading"
        :on-select-ad-groups="onSelectAdGroups"
        :on-select-i-o="onSelectIO"
        :on-select-media-types="onSelectMediaTypes"
        :set-filter-options="setFilterOptions"
        :filters="filters"
        @cancel="onCloseAdvanceFilters"
      />
    </div>
    <div v-if="showEventChangePopup.visible" class="show-event-change-popup">
      <EventConfirmModal
        :description="showEventChangePopup.description"
        @cancel="onCloseEventChangePopup"
        @change="onChangeEventChangePopup"
      />
    </div>
  </div>
  <div v-else class="dashboard-loading-spinner">
    <b-loading-spinner />
  </div>
</template>

<script>
import { sync, get } from 'vuex-pathify';
import { isBlank } from 'adready-api/helpers/common';
import planApi from 'adready-api/api/plans';
import { isEqual } from 'lodash';
import fetchIosMethodMixin from '@/components/mixins/fetch-ios-method-mixin';
import gaEventsMixin from '@/components/mixins/ga-events-mixin';
import fetchEventsMethodMixin from '@/components/mixins/fetch-events-method-mixin';
import fetchMediaTypesMethodMixin from '@/components/mixins/fetch-mediatypes-method-mixin';
import campaignMixin from '@/components/mixins/campaign-mixin';
import { buildProcessedEvent } from '~/helpers/global/event-helpers';
import advertiserReportsAPI from '~/api/advertiser-reports';
import { buildQueryString, getCachedFilters, setCacheFilters } from '~/helpers/global/url-helpers';
import authzMixin from '~/components/mixins/authz-mixin';
import filterMixin from '~/components/mixins/filter-mixins';
import AdvancedFiltersModal from '~/components/dash-panels/advanceFilters/advanced-filters-modal.vue';
import {
  DEFAULT_CONVERSION_WINDOW_DISPLAY,
  DEFAULT_CONVERSION_WINDOW_UNIT,
  DEFAULT_METHODOLOGY,
  UNCATEGORIZED_EVENT_CATEGORY,
  WINDOW_TYPE_CONVERSION,
  WINDOW_TYPE_REACH,
  WINDOW_TYPE_IMPRESSION,
  WINDOW_TYPE_LIFT,
  DATEPICKER_RANGE_OPTIONS,
  DATEPICKER_COMPARE_RANGE_OPTIONS,
  WINDOW_TYPE_INCREMENTALITY,
  WINDOW_TYPE_HALO_EFFECT,
  WINDOW_TYPE_BRAND_LIFT,
  ENUM_IS_WATCH_STATS,
  LAYOUT_TYPES,
} from '~/constant';
import config from '~/config';
import IntermediateScreen from '~/components/intermediate-screen.vue';
import EventConfirmModal from './EventConfirmModal.vue';
import {
  getCampaignDateRange,
  convertEpochToNYTimezone,
  generateFlipDefaultDates,
  isDemoInstance,
} from '~/util/utility-functions';
import ioHelpers from '~/helpers/io-helpers';

export default {
  components: {
    BLoadingSpinner: () =>
      import(
        /* webpackChunkName: "b-loading-spinner" */ '~/components/elements/b-loading-spinner.vue'
      ),
    KButton: () => import(/* webpackChunkName: "k-button" */ '~/components/elements/k-button.vue'),
    BRadioSelectCheckbox: () =>
      import(
        /* webpackChunkName: "b-radio-select-checkbox" */ '~/components/elements/b-radio-select-checkbox.vue'
      ),
    BSelectCheckbox: () =>
      import(
        /* webpackChunkName: "b-select-checkbox" */ '~/components/elements/b-select-checkbox.vue'
      ),
    BSelect: () => import(/* webpackChunkName: "b-select" */ '~/components/elements/b-select.vue'),
    BDatepicker: () =>
      import(/* webpackChunkName: "b-datepicker" */ '~/components/elements/b-datepicker.vue'),
    ExportTable: () =>
      import(
        /* webpackChunkName: "dash-export-table" */ '~/components/dash-panels/export/table.vue'
      ),
    GraphsPanel: () =>
      import(
        /* webpackChunkName: "dash-graphs-panel" */ '~/components/dash-panels/graphs/panel.vue'
      ),
    DashSummaryStats: () =>
      import(
        /* webpackChunkName: "dash-summary-stats" */ '~/components/dash-panels/summary/stats.vue'
      ),
    PerformancePanel: () =>
      import(
        /* webpackChunkName: "performance-panel" */ '~/components/dash-panels/performance/panel.vue'
      ),
    BRadioTabGroup: () =>
      import(
        /* webpackChunkName: "b-radio-tab-group" */ '~/components/elements/b-radio-tab-group.vue'
      ),
    BCheckbox: () =>
      import(/* webpackChunkName: "b-checkbox" */ '~/components/elements/b-checkbox.vue'),
    BFlipModal: () =>
      import(/* webpackChunkName: "b-flip-modal" */ '@/components/elements/b-flip-modal.vue'),
    IntermediateScreen,
    AdvancedFiltersModal,
    EventConfirmModal,
  },
  mixins: [
    fetchIosMethodMixin,
    fetchEventsMethodMixin,
    fetchMediaTypesMethodMixin,
    gaEventsMixin,
    campaignMixin,
    authzMixin,
    filterMixin,
  ],
  data() {
    return {
      WINDOW_TYPE_IMPRESSION,
      WINDOW_TYPE_REACH,
      WINDOW_TYPE_CONVERSION,
      WINDOW_TYPE_LIFT,
      isAdGroupLoading: false,
      filters: this.genFilters(),
      showIntermediateScreen: false,
      warningMessage: '',
      warningActionButtonTitle: '',
      pageTitle: '',
      emptyMessage: '',
      pageActionButton1Title: '',
      pageActionButton2Title: '',
      pageActionButton2Click: () => {},
      showAdvanceFilters: false,
      loadingFilters: false,
      selectedCampaignGoal: {},
      isEventLoading: false,
      showEventChangePopup: { visible: false, payload: {} },
      isIoOptsLoading: false,
    };
  },
  computed: {
    appliedFilterCount: get('common/appliedFilterCount'),
    showFilters: get('common/showFilters'),
    selectOneCampaignType: get('common/selectOneCampaignType'),
    mediaTypeOptions: get('dashboard/filters@mediaTypeOptions'),
    adGroupOptions: get('dashboard/filters@adGroupOptions'),
    ioOptions: get('dashboard/filters@ioOptions'),
    eventOptions: get('dashboard/filters@eventOptions'),
    totalOrUniqueRadioOptions: get('dashboard/filters@totalOrUniqueRadioOptions'),
    account: get('common/account'),
    advertiser: get('common/advertiser'),
    universalPixelId: sync('common/universalPixelId'),
    selectedMediaTypes: get('dashboard/GET_SELECTED_MEDIATYPES'),
    selectedPublishers: get('dashboard/GET_SELECTED_PUBLISHERS'),
    selectedCreatives: get('dashboard/GET_SELECTED_CREATIVES'),
    selectedAudiences: get('dashboard/GET_SELECTED_AUDIENCES'),
    selectedIOs: get('dashboard/GET_SELECTED_IOS'),
    selectedEvent: get('dashboard/GET_SELECTED_EVENT'),
    dates: get('dashboard/dates'),
    showExportTable: get('common/showExportTable'),

    isIncrementalReach() {
      return this.selectedEvent?.isIncrementalReach;
    },
    isVCRCategory() {
      return this.selectedEvent.isFalseEvent;
    },
    appliedFilterCountNew() {
      let appliedCount = 0;
      const filterUsed = this.filters;

      const filterKeys = [
        'ioOptions',
        'adGroupOptions',
        'mediaTypeOptions',
        'audienceOptions',
        'creativeOptions',
        'publisherOptions',
      ];

      filterKeys.forEach((key) => {
        if (filterUsed[key]?.some((v) => v.checked)) {
          appliedCount += 1;
        }
      });

      return appliedCount;
    },
    showLiftSummary() {
      if (this.selectedIOs.length !== 1) {
        return false;
      }
      const io = this.ioOptions.find((o) => o.value === this.selectedIOs[0]);
      return io && io.incrementalityEnabled && io.brandLiftEnabled;
    },
    isEmbed() {
      return true;
    },
  },
  watch: {
    async advertiser(n) {
      await this.validateAdvertiserSetup(n);
    },
    mediaTypeOptions(n) {
      this.filters.mediaTypeOptions = JSON.parse(JSON.stringify(n));
    },
    ioOptions(n) {
      this.filters.ioOptions = JSON.parse(JSON.stringify(n));
    },
    eventOptions(n) {
      this.filters.eventOptions = JSON.parse(JSON.stringify(n));
    },
    adGroupOptions(n) {
      this.filters.adGroupOptions = JSON.parse(JSON.stringify(n));
    },
    selectOneCampaignType(n) {
      const ioOptions = JSON.parse(JSON.stringify(this.filters.ioOptions));
      switch (n) {
        case WINDOW_TYPE_INCREMENTALITY:
        case WINDOW_TYPE_HALO_EFFECT:
          this.filters.ioOptions = ioOptions.map((option) => {
            let tempOption = option;
            tempOption = {
              ...option,
              checked: false,
              disabled: !option.incrementalityEnabled,
            };
            return tempOption;
          });
          break;
        case WINDOW_TYPE_BRAND_LIFT:
          this.filters.ioOptions = ioOptions.map((option) => {
            let tempOption = option;
            tempOption = {
              ...option,
              checked: false,
              disabled: !option.brandLiftEnabled,
            };
            return tempOption;
          });
          break;
        default:
          this.filters.ioOptions = ioOptions.map((option) => ({ ...option, disabled: false }));
          break;
      }
    },
  },
  created() {
    this.$on('on-select-advertiser', this.onSelectAdvertiser);
  },
  beforeDestroy() {
    this.$off('on-select-advertiser', this.onSelectAdvertiser);
    // making sure that the sidepanel is closed
    this.$store.dispatch('common/setShowFilters', false);
  },
  async mounted() {
    // if (!this.advertiser) {
    //   this.$router.push({ name: 'Home' });
    // }

    this.$store.dispatch('common/setAppliedFilterCount', this.appliedFilterCountNew);

    // check session storage for the user selected date
    const storedDate = sessionStorage.getItem('selectedDates');
    if (storedDate !== undefined && storedDate !== null) {
      this.$store.set('dashboard/dates', JSON.parse(storedDate));
    } else {
      let computedDate = await get('dashboard/dates');
      if (isDemoInstance()) {
        const { startDate, endDate, dateRangeOption } = this.dates;
        computedDate = { startDate, endDate, dateRangeOption };
        this.$store.set('dashboard/dates', {
          ...computedDate,
          ...this.filters.dateRange,
        });
      } else {
        const { startDate, endDate, dateRangeOption, compareWith } = generateFlipDefaultDates();
        computedDate = { ...this.dates, startDate, endDate, dateRangeOption, compareWith };
        this.$store.set('dashboard/dates', {
          ...this.dates,
          ...computedDate,
        });
      }
    }

    this.isIoOptsLoading = true;
    if (!this.account || !this.advertiser) {
      return;
    }
    this.$store.dispatch('dashboard/resetEventOptions');
    try {
      await this.fetchMediaTypes();
      await this.fetchIos();
      await this.fetchEvents();
      const selectedIoOptions = this.ioOptions.filter((io) => io.checked);
      if (selectedIoOptions.length === 1) {
        this.isEventLoading = true;
        await this.singleCampaignSettings(selectedIoOptions[0].campaignId, true);
        this.changeEventOptions();
        this.isEventLoading = false;
      } else {
        this.changeEventOptions();
        this.defaultCampaignSettings(true);
        this.selectedCampaignGoal = {};
      }
      await this.validateAdvertiserSetup(this.advertiser);
    } catch (err) {
      console.error('error mounting dashboard component ->', err);
      if (window.$sentry) {
        if (err._reported !== true) {
          window.$sentry.captureException(err);
          err._reported = true;
        }
      }
    }
    this.isIoOptsLoading = false;
    this.filters = this.genFilters();
    this.applyFilters(true);
  },
  methods: {
    onClearAdvanceFilters() {
      this.filters = this.clearAdvanceFilters(this.filters);
    },
    onCloseEventChangePopup() {
      this.showEventChangePopup = { visible: false, payload: {} };
    },
    onChangeEventChangePopup() {
      const { payload } = this.showEventChangePopup;
      const { newSelectedOptions } = payload;
      this.$store.set('dashboard/filters@eventOptions', newSelectedOptions);
      this.showEventChangePopup = { visible: false, payload: {} };
    },
    changeEventOptions(prevIoOptions = [], isCheckForPopup = false) {
      const newEventOptions = JSON.parse(JSON.stringify(this.eventOptions));
      const subCategory = this.selectedCampaignGoal?.subCategories;
      const newSelectedOptions = newEventOptions.map((ev) => {
        ev.selected = ev.category === this.selectedCampaignGoal?.category && ev.event !== 'Revenue';
        ev.open = ev.selected;
        const { children } = ev;
        children?.forEach((child) => {
          if (child.category === UNCATEGORIZED_EVENT_CATEGORY) {
            child.selected = false;
          } else {
            child.selected = subCategory?.includes(child.category);
          }
        });
        return ev;
      });
      if (newSelectedOptions.filter((sev) => !sev.selected).length === this.eventOptions?.length) {
        const selectedIoOptions = this.ioOptions.filter((io) => io.checked);

        if (!isBlank(selectedIoOptions)) {
          const ioOptionsWithGoal = selectedIoOptions.filter((opt) => opt?.goalCategory);
          const goalCategory = !isBlank(ioOptionsWithGoal)
            ? ioOptionsWithGoal[0]?.goalCategory
            : '';
          const selectedEvent = newSelectedOptions.find((ev) => ev.category === goalCategory);

          if (selectedEvent) {
            selectedEvent.selected = true;
          } else if (newSelectedOptions.length > 0) {
            newSelectedOptions[0].selected = true;
          }
        }
      }
      if (isCheckForPopup) {
        const prevOptionChecked = prevIoOptions.filter((opt) => opt.checked);
        const currentOptionsChecked = this.filters.ioOptions.filter((fopt) => fopt.checked);
        let restrictPopup = true;
        if (prevOptionChecked.length > 1 && currentOptionsChecked.length === 1) {
          restrictPopup = false;
        }
        if (
          prevOptionChecked.length <= 1 &&
          currentOptionsChecked.length <= 1 &&
          !(prevOptionChecked[0]?.value === currentOptionsChecked[0]?.value)
        ) {
          restrictPopup = false;
        }

        const selectedNewOption = newSelectedOptions.filter((nso) => nso.selected)?.[0] || {};
        const currentSelectedOption = this.eventOptions.filter((ept) => ept.selected)?.[0] || {};
        if (selectedNewOption.event !== currentSelectedOption.event && !restrictPopup) {
          this.showEventChangePopup = {
            visible: true,
            description: `Change Events Dropdown selection from ${currentSelectedOption.event} to ${selectedNewOption.event}?`,
            payload: {
              newSelectedOptions,
            },
          };
        }
        return;
      }
      this.$store.set('dashboard/filters@eventOptions', newSelectedOptions);
    },
    toggleShowExportTable(val) {
      this.$store.dispatch('common/toggleShowExportTable', val);
      this.$store.dispatch('common/setSelectedExportTableRow', '');
    },
    toggleFilterModal() {
      this.showAdvanceFilters = !this.showAdvanceFilters;
      document.body.className += ' advance-filters';
    },
    onCloseAdvanceFilters() {
      this.showAdvanceFilters = false;
      document.body.className = document.body.className?.replace(' advance-filters', '');
    },
    async validateAdvertiserSetup(n) {
      if (!n) {
        this.showIntermediateScreen = false;
        return;
      }
      if (!n?.selfServe) {
        this.showIntermediateScreen = false;
        return;
      }
      this.showIntermediateScreen = this.doesAdvertiserHaveNoCampaignsForReporting();
    },
    doesAdvertiserHaveNoCampaignsForReporting() {
      const hasNoCampaignForReporting = !(this.ioOptions && this.ioOptions.length > 0);
      if (hasNoCampaignForReporting) {
        this.warningMessage = 'Your campaign has to start before you can view reporting';
        this.warningActionButtonTitle = 'Manage Campaigns';
        this.warningActionButtonClick = this.openManageCampaigns;
        this.pageTitle = 'Reporting';
        this.emptyMessage = 'No campaigns have started yet.';
        this.pageActionButton1Title = 'Manage Campaigns';
        this.pageActionButton1Click = this.openManageCampaigns;
      }
      return hasNoCampaignForReporting;
    },
    openAccountSetup() {
      this.$store.dispatch('common/setIsWatchStats', ENUM_IS_WATCH_STATS);
      this.$store.dispatch('common/setSelectedMenuOptions', LAYOUT_TYPES.ACCOUNT_SETUP);
    },
    openManageCampaigns() {
      this.$store.dispatch('common/setIsWatchStats', ENUM_IS_WATCH_STATS);
      this.$store.dispatch('common/setSelectedMenuOptions', LAYOUT_TYPES.CAMPAIGNS);
    },
    async openCloseFilters() {
      this.$store.dispatch('common/setShowFilters', !this.showFilters);
      this.$store.dispatch('common/setSelectOneCampaignType', '');

      this.filters = this.genFilters();
    },
    setFilterOptions(name, values) {
      this.filters[name] = values;
      this.$store.dispatch('common/setAppliedFilterCount', this.appliedFilterCountNew);
    },
    genFilters() {
      const cachedFilters = getCachedFilters();
      if (cachedFilters) {
        return cachedFilters;
      }
      const ioOptions = this.$store.copy('dashboard/filters@ioOptions');
      const totalOrUniqueRadioOptions = this.$store.copy(
        'dashboard/filters@totalOrUniqueRadioOptions'
      );
      const showUniques = this.$store.copy('dashboard/filters@showUniques');
      if (showUniques) {
        totalOrUniqueRadioOptions[1].active = true;
        totalOrUniqueRadioOptions[0].active = false;
      }
      const methodologyOptions = this.$store.copy('dashboard/filters@methodologyOptions');
      const methodology = this.$store.copy('dashboard/filters@methodology');
      methodologyOptions.forEach((mo) => {
        mo.active = methodology === mo.id;
      });
      return {
        closeTimeout: null,
        mediaTypeOptions: this.$store.copy('dashboard/filters@mediaTypeOptions'),
        audienceOptions: this.$store.copy('dashboard/filters@audienceOptions'),
        creativeOptions: this.$store.copy('dashboard/filters@creativeOptions'),
        publisherOptions: this.$store.copy('dashboard/filters@publisherOptions'),
        ioOptions,
        eventOptions: this.$store.copy('dashboard/filters@eventOptions'),
        attributionOptions: 'full',
        totalOrUniqueRadioOptions,
        conversionWindow: this.$store.copy('dashboard/filters@conversionWindow'),
        conversionWindowDisplay: this.$store.copy('dashboard/filters@conversionWindowDisplay'),
        conversionWindowUnit: this.$store.copy('dashboard/filters@conversionWindowUnit'),
        methodologyOptions,
        methodology,
        adGroupOptions: this.$store.copy('dashboard/filters@adGroupOptions'),
      };
    },
    onSelectAdvertiser() {
      this.filters.mediaTypeOptions = this.mediaTypeOptions;
      this.filters.ioOptions = this.ioOptions;
      this.filters.eventOptions = this.eventOptions;
    },
    onSelectEvents(payload, payloadChild) {
      this.$store.dispatch('common/setIsWatchStats', {
        IMPRESSION: true,
        REACH: true,
        CONVERSION: true,
        LIFT: true,
      });
      const selectedIndex = payload.findIndex((p) => p.selected);
      const formattedPayload = payload;
      if (selectedIndex !== -1) {
        formattedPayload[selectedIndex].children = formattedPayload[selectedIndex]?.children?.map(
          (child) => {
            if (
              child.category === UNCATEGORIZED_EVENT_CATEGORY &&
              payloadChild?.category !== UNCATEGORIZED_EVENT_CATEGORY
            ) {
              child.selected = false;
            }
            return child;
          }
        );
      }

      this.$store.set(
        'dashboard/filters@eventOptions',
        JSON.parse(JSON.stringify(formattedPayload))
      );
      const selectedEvent = buildProcessedEvent(formattedPayload);
      const toggleOptions = [...this.filters.totalOrUniqueRadioOptions];

      if (!selectedEvent.isRevenueEvent) {
        toggleOptions[1].disabled = false;
      } else {
        if (this.filters.totalOrUniqueRadioOptions[1].active) {
          toggleOptions[0].active = true;
          toggleOptions[1].active = false;
        }
        toggleOptions[1].disabled = true;
      }

      this.$store.set(
        'dashboard/filters@totalOrUniqueRadioOptions',
        JSON.parse(JSON.stringify(toggleOptions))
      );
      // this.$store.set('dashboard/filters@showUniques', false);
      this.$store.set(
        'dashboard/filters@showUniques',
        toggleOptions[1].active && !toggleOptions[1].disabled
      );
      this.$store.dispatch('common/setSelectOneCampaignType', '');
      this.$store.dispatch('common/setShowFilters', false);
    },
    onlyNumber($event) {
      const keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    onSelectMethodology(method) {
      this.filters.methodology = method;
    },
    updateTotalOrUniqueRadioOptions(payload) {
      this.filters.totalOrUniqueRadioOptions = payload;
    },
    updateConversionWindowUnit(val) {
      this.filters.conversionWindowUnit = val.name.toUpperCase();
    },
    truncateStr(str, n) {
      return str.length > n ? `${str.substr(0, n - 1)}...` : str;
    },
    onclickFilterSec() {
      if (this.filters.closeTimeout) {
        clearTimeout(this.filters.closeTimeout);
        this.filters.closeTimeout = null;
      }
    },
    applyFilters(initialLoad = false) {
      const prevIoOptions = this.$store.copy('dashboard/filters@ioOptions');
      this.$store.dispatch('common/setShowFilters', false);
      this.$store.dispatch('common/setSelectOneCampaignType', '');
      this.$store.dispatch('common/setIsWatchStats', {
        IMPRESSION: true,
        REACH: true,
        CONVERSION: true,
        LIFT: true,
      });
      this.$store.set('dashboard/filters@mediaTypeOptions', this.filters.mediaTypeOptions);
      this.$store.set('dashboard/filters@methodology', this.filters.methodology);
      this.$store.set('dashboard/filters@adGroupOptions', this.filters.adGroupOptions);

      if (!initialLoad) {
        // Here if campaigns are modified then we are resetting the dates.
        if (!isEqual(this.ioOptions, this.filters.ioOptions)) {
          const dateRange = getCampaignDateRange(this.filters.ioOptions, true);
          this.filters.dateRange = dateRange;
          this.$store.set('dashboard/dates', {
            ...this.dates,
            ...this.filters.dateRange,
          });
        }
      }
      this.$store.set(
        'dashboard/filters@ioOptions',
        this.filters.ioOptions.map((option) => ({ ...option, disabled: false }))
      );
      this.$store.set(
        'dashboard/filters@showUniques',
        this.filters.totalOrUniqueRadioOptions[1].active &&
          !this.filters.totalOrUniqueRadioOptions[1].disabled
      );
      this.$store.set(
        'dashboard/filters@conversionWindowDisplay',
        this.filters.conversionWindowDisplay
      );
      this.$store.set(
        'dashboard/filters@conversionWindow',
        this.filters.conversionWindowDisplay *
          (this.filters.conversionWindowUnit === 'DAYS' ? 24 : 1)
      );
      if (this.filters?.ioOptions.filter((f) => f?.checked)?.length === 1) {
        this.changeEventOptions(prevIoOptions, true);
      } else {
        this.changeEventOptions(prevIoOptions, false);
      }
      setCacheFilters(JSON.stringify(this.filters));

      this.$store.set('dashboard/filters@conversionWindowUnit', this.filters.conversionWindowUnit);
      this.$store.set('dashboard/filters@audienceOptions', this.filters.audienceOptions);
      this.$store.set('dashboard/filters@creativeOptions', this.filters.creativeOptions);
      this.$store.set('dashboard/filters@publisherOptions', this.filters.publisherOptions);
      this.fireSettingsChangedGAEvent();
      this.filters = this.genFilters();
      this.$store.dispatch('common/setAppliedFilterCount', this.appliedFilterCountNew);
    },
    fireSettingsChangedGAEvent() {
      if (config.gaEnabled()) {
        const gaEv = {
          media_type_options:
            this.filters.mediaTypeOptions.filter((o) => o.checked)?.map((o) => o.value) || [],
          methodology: this.filters.methodology,
          io_options:
            JSON.stringify(
              this.filters.ioOptions
                .filter((o) => o.checked)
                ?.map((o) => {
                  return {
                    value: o.value,
                  };
                })
            ) || [],
          attribution_options: 'full',
          show_uniques:
            this.filters.totalOrUniqueRadioOptions[1].active &&
            !this.filters.totalOrUniqueRadioOptions[1].disabled,
          conversion_window_display: this.filters.conversionWindowDisplay,
          conversion_window_unit: this.filters.conversionWindowUnit,
        };
        this.fireGAEvent('flp_sett_change', gaEv);
      }
    },

    onSelectMediaTypes(data) {
      this.filters.mediaTypeOptions = data;
    },
    async onSelectIO(data) {
      this.isAdGroupLoading = true;
      let result = '';
      result = data.filter((f) => f?.checked);
      // This is the code that make campaign dropdown get closed when one single select is left
      // Commenting below 5 line of code as per requirment.  Can be useed in future
      if (result?.length === 1 && !isBlank(result[0].campaignId) && result[0].campaignId > 0) {
        this.singleCampaignSettings(result[0].campaignId);
      } else {
        this.selectedCampaignGoal = {};
        this.defaultCampaignSettings();
      }
      this.selectedCampaignGoal = {};
      this.defaultCampaignSettings();
      let adGroupData = [];
      let response = '';
      const planIds =
        result
          ?.map((res) => {
            return res.campaignId;
          })
          ?.filter((fres) => fres)
          ?.join(',') || '';
      if (planIds) {
        response = await advertiserReportsAPI.adGroupOptions(
          this.advertiser ? this.advertiser.id : 0,
          buildQueryString({ planIds })
        );
        if (response?.data?.length > 0) {
          adGroupData = response?.data;
        }
      }

      if (adGroupData.length > 0) {
        adGroupData = adGroupData.map((agd) => {
          return { ...agd, value: agd?.lineItemName || '', checked: true };
        });
      }
      this.isAdGroupLoading = false;
      this.filters.ioOptions = data;
      this.filters.adGroupOptions = adGroupData;
      this.filters.mediaTypeOptions = await this.fetchSpecificMediaTypeOptions();
    },
    async onSelectAdGroups(data) {
      this.filters.adGroupOptions = data;
      this.filters.mediaTypeOptions = await this.fetchSpecificMediaTypeOptions();
    },
    defaultCampaignSettings(isMounted = false) {
      const totalOrUniqueOptions = this.$store
        .copy('dashboard/filters@totalOrUniqueRadioOptions')
        ?.map((attr, index) => {
          attr.active = index === 0;
          attr.disabled = false;
          return attr;
        });
      this.onSelectMethodology(DEFAULT_METHODOLOGY);
      this.updateConversionWindowUnit({
        name: DEFAULT_CONVERSION_WINDOW_UNIT,
      });
      this.filters.conversionWindowDisplay = DEFAULT_CONVERSION_WINDOW_DISPLAY;
      this.updateTotalOrUniqueRadioOptions(totalOrUniqueOptions);
      if (isMounted) {
        this.$store.set(
          'dashboard/filters@conversionWindowDisplay',
          DEFAULT_CONVERSION_WINDOW_DISPLAY
        );
        this.$store.set(
          'dashboard/filters@conversionWindowUnit',
          DEFAULT_CONVERSION_WINDOW_UNIT.toUpperCase()
        );
        this.$store.set(
          'dashboard/filters@conversionWindow',
          this.filters.conversionWindowDisplay *
            (DEFAULT_CONVERSION_WINDOW_UNIT.toUpperCase() === 'DAYS' ? 24 : 1)
        );
        this.$store.set('dashboard/filters@methodology', DEFAULT_METHODOLOGY);
        this.$store.set('dashboard/filters@showUniques', false);
      }
    },
    async singleCampaignSettings(campaignId, isMounted = false) {
      this.loadingFilters = true;
      let flipCampaignInfo = {};
      let source = 'ADREADY';
      if (campaignId) {
        const resPlan = await planApi.read(campaignId, {
          isLineItemsRequired: false,
        });
        flipCampaignInfo = resPlan.flipCampaignInfo || {};
        source = resPlan.source;
      }

      this.selectedCampaignGoal = flipCampaignInfo?.goal ? flipCampaignInfo?.goal : {};
      const totalOrUniqueRadioOptions = this.$store.copy(
        'dashboard/filters@totalOrUniqueRadioOptions'
      );
      if (!flipCampaignInfo?.optimization?.impressions) {
        totalOrUniqueRadioOptions[1].active = false;
        totalOrUniqueRadioOptions[0].active = true;
      } else {
        totalOrUniqueRadioOptions.forEach((tuo) => {
          tuo.active =
            flipCampaignInfo?.optimization?.impressions?.value.toUpperCase() === tuo.text || false;
        });
      }
      this.updateTotalOrUniqueRadioOptions(totalOrUniqueRadioOptions);
      const conversionWindowObj = {
        name: flipCampaignInfo?.conversionWindow?.unit?.value || DEFAULT_CONVERSION_WINDOW_UNIT,
      };
      const methodologyOptions = this.$store.copy('dashboard/filters@methodologyOptions');
      let methodologyOption = {};

      methodologyOption = methodologyOptions.find((mo) => {
        return mo.name.toUpperCase() === flipCampaignInfo?.methodology?.value?.toUpperCase();
      });
      let methodologyOptionId = methodologyOption?.id;
      if (source && source === 'PIO') {
        this.filters.conversionWindowDisplay = flipCampaignInfo?.conversionWindow;
        const methodology = flipCampaignInfo?.methodology;
        if (methodology) {
          methodologyOptionId = ioHelpers.getMethodology(methodology.toLowerCase());
        }
      } else {
        this.filters.conversionWindowDisplay = flipCampaignInfo?.conversionWindow?.value;
      }
      if (!this.filters.conversionWindowDisplay) {
        this.filters.conversionWindowDisplay = DEFAULT_CONVERSION_WINDOW_DISPLAY;
      }

      this.updateConversionWindowUnit(conversionWindowObj);
      this.onSelectMethodology(methodologyOptionId || DEFAULT_METHODOLOGY);
      if (isMounted) {
        this.$store.set(
          'dashboard/filters@conversionWindowDisplay',
          this.filters.conversionWindowDisplay
        );
        this.$store.set(
          'dashboard/filters@conversionWindowUnit',
          conversionWindowObj.name.toUpperCase()
        );
        this.$store.set(
          'dashboard/filters@conversionWindow',
          this.filters.conversionWindowDisplay *
            (conversionWindowObj.name.toUpperCase() === 'DAYS' ? 24 : 1)
        );
        this.$store.set(
          'dashboard/filters@methodology',
          methodologyOptionId || DEFAULT_METHODOLOGY
        );
        this.$store.set(
          'dashboard/filters@showUniques',
          totalOrUniqueRadioOptions[1].active && !totalOrUniqueRadioOptions[1].disabled
        );
      }
      this.loadingFilters = false;
    },

    bDatepickerChanges(data) {
      this.$store.set(`common/RESET_CONVERSION_WINDOW`);
      const datePickerPayload = {
        startDate: convertEpochToNYTimezone(data.dates[0]),
        endDate: convertEpochToNYTimezone(data.dates[1].toDateString()),
        compareWith: data.compareWith,
        compareStartDate: convertEpochToNYTimezone(data.dates[2]),
        compareEndDate: convertEpochToNYTimezone(data.dates[3]),
        dateRangeOption: data.dateRangeOption,
        dateCompareOption: data.dateCompareOption,
      };
      this.$store.set('dashboard/dates', datePickerPayload);
      sessionStorage.setItem('selectedDates', JSON.stringify(datePickerPayload));
      this.datePickerChangedGAEvent(datePickerPayload);
      this.$store.dispatch('common/setIsWatchStats', {
        IMPRESSION: true,
        REACH: true,
        CONVERSION: true,
        LIFT: true,
      });
      this.$forceUpdate();
    },
    datePickerChangedGAEvent(datePickerPayload) {
      if (config.gaEnabled()) {
        const {
          startDate,
          endDate,
          compareWith,
          compareStartDate,
          compareEndDate,
          dateRangeOption,
          dateCompareOption,
        } = datePickerPayload;

        const gaEv = {
          start_date: startDate,
          end_date: endDate,
          compare: compareWith,
          date_range_option: DATEPICKER_RANGE_OPTIONS[`${dateRangeOption}`],
        };

        if (compareWith) {
          gaEv.compare_start_date = compareStartDate;
          gaEv.compare_end_date = compareEndDate;
          gaEv.compare_date_option = DATEPICKER_COMPARE_RANGE_OPTIONS[`${dateCompareOption}`];
        }
        this.fireGAEvent('flp_dtpk_change', gaEv);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen {
  .dashboard-page-loader {
    position: relative;
    top: 250px;
  }
  * {
    box-sizing: border-box;
  }
  * {
    outline: 0;
  }
  .filter-details {
    display: inline-block;
    margin-right: 0.2rem;
    cursor: pointer;
    // &:hover {
    //   background-color: rgba(0, 0, 0, 0.2);
    // }

    .filter {
      display: inline-block;
      padding: 0 4px 0 4px;
      margin-right: 8px;
      text-align: left;

      &:last-child {
        margin-left: 10px;
        border-right: 1px dashed #232222;
        div {
          margin-right: 5px;
        }
      }

      div {
        font-size: 12px;

        &.main {
          padding: 2px 0 2px 0;
          font-weight: 500;
          color: #4c5461;
        }

        &.sub {
          font-weight: 600;
          color: #667184;
        }
      }
    }
  }
  .filter-details.lookup {
    position: relative;
    display: inline-block;
    padding: 8px;
    color: #717482;
    vertical-align: text-bottom;
    cursor: pointer;
    span.main {
      font-size: 11px;
      font-weight: 300;
      color: #717482;
      span.sub {
        display: inline-block;
        width: 70px;
        margin-top: -5px;
        overflow: hidden;
        font-size: 12px;
        font-weight: 400;
        color: #a1a5af;
        text-align: right;
        text-overflow: ellipsis;
        white-space: nowrap;
        vertical-align: top;
        cursor: pointer;
      }
      svg {
        position: relative;
        display: inline-flex;
        align-items: center;
        font-size: 10px;
        color: #ccc;
      }
    }
  }
  .light-theme {
    .top-export-icon {
      color: #444;
    }
  }
  .top-export-icon {
    position: relative;
    display: inline-block;
    margin-top: 10px;
    margin-right: 30px;
    font-size: 21px;
    color: #ddd;
    vertical-align: top;
  }
  .top-export-icon.downloadIcon {
    margin: 12px 5px 0px 10px;
  }

  .top-export-icon:hover {
    color: var(--primarycolor);
  }
}

@media screen {
  * {
    box-sizing: border-box;
  }
  .light-theme .flp-panel {
    color: #212325;
    box-shadow: 0px 14px 35px -12px rgba(0, 40, 100, 0.17);
    background-color: #fff;
    border: 1px solid rgb(165 173 178 / 22%);
    border-radius: 8px;
  }
  .flp-panel {
    position: relative;
    display: inline-block;
    padding: 30px;
    color: #fff;
    background-color: #2f323a;
    border-radius: 3px;
    -webkit-transition: background-color 0.3s ease;
    -moz-transition: background-color 0.3s ease;
    -ms-transition: background-color 0.3s ease;
    -o-transition: background-color 0.3s ease;
    transition: background-color 0.3s ease;
  }
  .flp-panel-title {
    display: inline-block;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.1em;
  }
  .input1 {
    position: relative;
    display: inline-block;
    width: 200px;
    min-width: 150px;
    height: 100%;
    padding: 10px;
    font-size: 15px;
    text-align: left;
    border: 0;
    border-radius: 4px;
  }
  .inputdark {
    color: #fff;
    background-color: #262933;
  }

  .wrap4icon.icon-cal {
    position: relative;
    display: inline-block;
  }
  * {
    outline: 0;
  }
  .filter-panel-wrap {
    align-items: center;
    margin-bottom: 0;
    color: #cad1d6;
    border-top: 1px solid #212429;
    transition: left 0.3s ease;
    .fp-text-link {
      font-size: 12px;
      color: #707e8a;
      display: inline-block;
      margin-top: 15px;
      display: flex;
      align-items: center;
      & p {
        text-decoration: underline;
        cursor: pointer;
      }
      & p:hover {
        color: var(--primarycolor) !important;
      }
      & span {
        text-decoration: none;
        margin-left: 8px;
        color: #cad1d6;
        .advance-filter-clear-icon:hover {
          color: var(--primarycolor) !important;
        }
      }
      & span:hover {
        color: #cad1d6;
      }
    }
  }
  .exportable-table-wrap {
    min-height: 20rem;
    max-height: 30rem;
    margin-bottom: 35px;
    overflow: hidden;
    transition: all 0.3s ease;
  }
  .ex-close {
    padding: 9px 2px 9px 20px;
  }
  .ex-close:hover {
    color: var(--primarycolor);
  }
  .flp-panel-top {
    padding-bottom: 20px;
    margin-bottom: 14px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .fright {
    position: absolute;
    right: 0px;
    display: flex;
    width: 240px;
    margin-top: -1%;
  }
  .overflowshow {
    overflow: visible;
  }
  .intip4 {
    position: relative;
    top: 0;
  }
  .intip4[data-tool-tip]::after {
    position: absolute;
    bottom: 100%;
    left: 0;
    z-index: 99;
    display: block;
    width: 100px;
    padding: 10px 12px;
    margin-left: -50px;
    font-size: 12px;
    line-height: 1.2em;
    color: #5b5b5b;
    text-align: center;
    pointer-events: none;
    content: attr(data-tool-tip);
    background-color: #fdea85;
    border-radius: 3px;
    transition: ease 0.3s;
    transform: scale(0);
    transform-origin: bottom center;
  }
  .intip4[data-tool-tip]::after {
    width: 155px;
    margin-left: -70px;
    font-size: 12px;
    font-weight: 400;
    color: #222;
    pointer-events: none;
    background-color: rgba(245, 245, 250, 0.9);
  }
  .intip4[data-tool-tip]:hover::after {
    bottom: -145%;
    transform: scale(1);
  }
}
::v-deep .media-settings {
  .filter-group > *:not(:first-child) {
    margin-top: 12px !important;
  }
  .media-select {
    // width: 180px !important;
    // min-width: 180px !important;
  }
  .dropdown {
    width: 180px !important;
    min-width: 180px !important;
    margin: 0 0 0 0 !important;
    &:not(:first-child) {
      // margin-left: 15px !important;
    }
  }
}
::v-deep .method-settings {
  .dropdown {
    width: 198px !important;
    min-width: 198px !important;
    &:not(:first-child) {
      // margin-left: 15px !important;
    }
  }
  .filter-group {
    .tot-uniq,
    .conv-content {
      // margin-left: 15px !important;
      .conversion-opt-cont {
        display: flex;
      }
    }
  }
}
.light-theme {
  .filter-panel-wrap {
    .filter {
      .filter-group {
        .flp-input {
          color: #212325;
          background-color: #fff;
          border: 1px solid #c5d2d8;
        }
      }
      ::v-deep .dropdown .dropdown-menu {
        li svg {
          color: #555 !important;
          &.check-off {
            color: transparent !important;
          }
        }
      }
    }
  }
  .no-right-mg {
    right: -11px !important;
  }
}
::v-deep .filter-panel-wrap .filter.media-settings .dropdown .dropdown-menu {
  max-height: 300px !important;
  overflow: hidden !important;
  overflow-y: auto !important;
  li svg {
    font-size: 17px !important;
    box-shadow: inset 0px 0px 1px 1px #454546;
    color: #fff;
  }
}
.dropdown.events-dd {
  width: 192px !important;
  margin-right: 10px !important;
}
::v-deep .dropdown.events-dd .select {
  min-height: auto !important;
}
.inl-blk {
  display: inline-block;
}
.margin-btm-10 {
  margin-bottom: 10px;
}

.vue-daterange-picker {
  color: black !important;
}

.expand-table-enter-active,
.expand-table-leave-active {
  // -webkit-transition: left 0.3s ease, opacity 0.3s ease;
  // -moz-transition: left 0.3s ease, opacity 0.3s ease;
  // -ms-transition: left 0.3s ease, opacity 0.3s ease;
  // -o-transition: left 0.3s ease, opacity 0.3s ease;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.expand-table-enter,
.expand-table-leave-to {
  transform: translateX(-100%) !important;
  opacity: 0 !important;
}

// Top Area CSS - START

.top-area {
  margin-bottom: 15px;
  color: #fff;
  margin-top: 15px;
}
.logo {
  display: inline-block;
  width: 250px;
  height: auto;
}
.logo img {
  width: 100%;
  height: auto;
}
.top-area-center {
  position: relative;
  top: 0;
  left: -125px;
  display: flex;
  margin: 0;
}
.top-area-center-checkbox {
  position: relative;
  display: inline-block;
  margin-left: 40px;
}
label {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
input[type='checkbox'] {
  display: none;
}
input[type='checkbox'] + label:before {
  display: inline-block;
  font-family: 'FontAwesome';
}
input[type='checkbox'] + label:after {
  display: inline-block;
  font-family: 'FontAwesome';
}
input[type='checkbox'] + label:before {
  position: relative;
  top: -2px;
  left: 2px;
  z-index: 2;
  font-size: 14px;
  font-weight: 600;
  color: var(--primarycolor);
  letter-spacing: 10px;
  content: '\f00c';
  opacity: 0;
}
input[type='checkbox']:checked + label:before {
  font-weight: 600;
  letter-spacing: 10px;
  content: '\f00c';
  opacity: 1;
}
input[type='checkbox'] + label::after {
  position: absolute;
  left: 0px;
  font-size: 18px;
  font-weight: 500;
  color: rgba(150, 150, 150, 0.4);
  content: '\f1db';
}
input[type='checkbox']:checked + label::after {
  color: var(--primarycolor);
}
.top-area-right {
  position: inherit;
  display: inline-block;
  // float: right;
  // width: 972px;
  width: 1220px;
  min-height: 50px;
  // overflow-x: scroll;
  // text-align: right;
  white-space: nowrap;
}
.input-value {
  position: relative;
  display: inline-block;
  width: 200px;
  min-width: 150px;
  height: 100%;
  padding: 10px 10px;
  font-size: 15px;
  text-align: left;
  border: 0px;
  border-radius: 4px;
}
.icon-cal {
  position: relative;
  top: -6px;
  display: inline-flex;
  float: right;
  vertical-align: top;
  right: 20px;
}
::v-deep .icon-cal .wrap svg {
  position: absolute;
  top: 50%;
  right: 16px;
  z-index: 1;
  margin-top: -11px;
  pointer-events: none;
}
.intdark {
  color: #fff;
  background-color: #262933;
}
// Top Area CSS - END

// Last Area CSS - START

.last-area {
  margin-top: 60px;
}

.nav-tabs {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  display: inline-block;
  margin: -33px 0px 0px 0;
}
.light-theme .left-part {
  background-color: #fff;
  color: #212325;
  box-shadow: 0px 14px 35px -12px rgba(0, 40, 100, 0.17);
  border: 1px solid rgb(165 173 178 / 22%);
  border-radius: 8px;
}

.left-part {
  position: relative;
  z-index: 2;
  float: left;
  width: 30%;
  min-width: 366px;
  min-height: 585px;
  padding: 30px 0px;
  color: #fff;
  background-color: #212429;
  border-radius: 0px;
}
.filter-group.mediaFilters {
  display: flex;
  flex-direction: column;
}
.light-theme .right-part {
  color: #212325;
  box-shadow: 0px 14px 35px -12px rgba(0, 40, 100, 0.17);
  background-color: #fff;
  border: 1px solid rgb(165 173 178 / 22%);
  border-radius: 8px;
}
.right-part {
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  float: left;
  width: 70%;
  min-width: 648px;
  min-height: 585px;
  padding: 30px 20px;
  margin-left: 25px;
  color: #fff;
  background-color: #212429;
  border-radius: 0px;
}

// Last Area CSS - END

// Download Icon CSS - START

svg:not(:root).svg-inline--fa {
  overflow: visible;
}
.svg-inline--fa {
  display: inline-block;
  height: 1em;
  overflow: visible;
  font-size: inherit;
  vertical-align: -0.125em;
}
.svg-inline--fa.fa-w-12 {
  width: 0.75em;
}

.fa-arrow-to-bottom:before {
  content: '\f33d';
}

// Download Icon CSS - END

// Export Table - START

svg:not(:root).svg-inline--fa {
  overflow: visible;
}
.svg-inline--fa {
  display: inline-block;
  height: 1em;
  overflow: visible;
  font-size: inherit;
  vertical-align: -0.125em;
}
.svg-inline--fa.fa-w-10 {
  width: 0.625em;
}

.fa-times:before {
  content: '\f00d';
}

.fa-times:before {
  content: '\f00d';
}
.top-last {
  margin-top: 0px;
}

.container-chart {
  position: absolute;
  top: 120px;
  width: 100%;
  height: 100%;
}

.chart-filters {
  display: flex;
  .chart-widget {
    position: absolute;
    right: 55px;
  }
  .flp-opt-panel {
    top: 40px;
    right: 30px;
  }
}
::v-deep .dropdown {
  margin-right: 0 !important;
}

::v-deep .chart-filters .dropdown .dropdown-menu li {
  font-size: 14px;
}
::v-deep .exportable-table-wrap .flp-panel-top .form-input-wrap {
  margin-top: -1% !important;
}
::v-deep .form-input-field {
  background-color: #262933;
  border: none;
  border-radius: 4px;
}
::v-deep .form-input-field.v-select .vs__dropdown-toggle .vs__actions .action-icons svg {
  color: inherit !important;
}
::v-deep .form-input-wrap .form-click .action-icons {
  padding: 0 11px 0 0 !important;
}
::v-deep .form-input-wrap .form-click {
  min-height: 40px;
}
::v-deep .form-input.form-date svg {
  left: 90%;
  z-index: 1;
}
.exportable-table-wrap .flp-panel-top {
  display: flex;
}
.exportable-table-wrap .dboard-select {
  width: 12rem;
  min-width: 150px;
  margin-top: -10px;
  margin-left: 1rem;
  background-color: #474a56;
  border-radius: 4px;
}
.exportable-table-wrap .flp-panel-top .flp-panel-title {
  width: 175px;
}
.exportable-table-wrap .flp-controls {
  display: flex;
  width: 100%;
}
.dboard-select {
  width: 12rem;
  min-width: 182px;
  margin-top: 4px;
  margin-right: 1.2rem;
  margin-left: 0;
  background-color: #474a56;
  border-radius: 4px;
}
::v-deep .icon-cal .form-input-field {
  width: 190px;
  margin: 4px 0 0 0;
  cursor: pointer;
}
::v-deep .k-btn {
  display: inline-block;
  min-width: 75px;
  font-weight: 600;
  color: var(--buttontext) !important;
  text-transform: uppercase;
  letter-spacing: 0.07em;
  background-color: var(--primarycolor);
  border-radius: 3px;
  appearance: none;
}
::v-deep .k-btn.btn-size-3 span.label {
  padding: 9px 22px;
  line-height: 20px;
}
::v-deep .k-btn:hover {
  color: #cad1d6 !important;
  background-color: #424242 !important;
}
.light-theme {
  .filter-panel-wrap {
    background-color: #fbfcfe;
    box-shadow: 10px 20px 20px rgb(63 66 77 / 16%);
    color: #212325;
    border-right: 1px solid #f2f6ff;
    border-top: unset;
    svg {
      color: #212325 !important;
    }
    .fp-text-link {
      p {
        color: #707e8a !important;
      }
      span {
        color: #212325 !important;
      }
    }
  }
}
.filter-panel-wrap {
  position: fixed;
  top: 55px;
  bottom: 0;
  left: 0;
  z-index: 3;
  display: block;
  width: auto;
  min-width: 360px;
  height: 94vh;
  padding: 30px 80px 20px;
  background: #121215;
  background-color: rgb(16 16 19 / 93%);
  border-right: 1px solid #1d1f23;
  box-shadow: inset -10px 10px 20px rgb(0 0 0 / 30%);
  box-shadow: 10px 30px 30px rgb(0 0 0 / 50%);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  backdrop-filter: saturate(140%) blur(5px);
  span {
    margin-top: 2px;
  }
  svg {
    color: #cad1d6 !important;
  }
  .filter {
    ::v-deep .dropdown .dropdown-menu {
      overflow: unset !important;
      overflow-y: unset !important;
      li svg {
        font-size: inherit !important;
      }
      .intip4 {
        top: 2px !important;
        margin-left: 5px !important;
        &::after {
          bottom: 200% !important;
          width: 198px !important;
          margin-left: -73px !important;
          font-weight: 400 !important;
          text-align: left !important;
          letter-spacing: 0em !important;
        }
      }
    }
    .filter-ttl {
      margin-bottom: 10px;
      font-size: 13px;
      font-weight: 400;
      line-height: 1.1em;
      text-transform: uppercase;
      letter-spacing: 0.05em;
    }

    .filter-group > *:not(:first-child) {
      margin-top: 10px;
    }

    .filter-group {
      margin-bottom: 30px;
      .conv-content {
        position: relative;
        margin-top: 30px;
        // display: inline-block;

        .conv-ttl {
          position: absolute;
          top: -80%;
          font-size: 14px;
          color: #707e8a;
        }
      }

      .opt-b1 {
        display: inline-block;
        margin-right: 6px;
      }
      .opt-b2 {
        display: inline-block;
        width: 5rem;
        margin-left: 70px;
        vertical-align: bottom;
        position: fixed;
        & .checkbox-btn {
          height: unset;
        }
        ::v-deep .checkbox-btn {
          span {
            position: fixed;
            margin: 0 30px !important;
            font-size: 12px !important;
          }
        }
      }

      .input-conv {
        width: 60px;
      }

      .flp-input {
        padding: 10px;
        color: #ddd;
        background-color: transparent;
        border: 1px solid rgba(170, 170, 170, 0.2);
        border-radius: 4px;
      }
    }
  }

  .close-filters {
    position: absolute;
    top: 10px;
    right: 0;
    margin-right: 20px;
    cursor: pointer;
    &:hover {
      color: var(--primarycolor) !important;
    }
  }
}
.no-display {
  display: none !important;
}

.sideopen {
  position: relative;
  left: 12%;
  width: calc(97% - 300px);
  .filter-panel-wrap {
    left: 0px;
  }
}
.no-embed {
  padding-left: 50;
}
.trans-effect {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

// end of new header
::v-deep .modal-mask {
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999 !important;
  background-color: rgba(0, 0, 0, 0.8) !important;
  .modal-wrapper {
    max-width: initial;
    max-height: initial;
    overflow: hidden !important;
    .modal-container {
      background-color: transparent;
      padding: 0;
      min-width: 1250px;
      .exportable-table-wrap {
        max-height: initial;
      }
      .table-scroll {
        max-height: initial;
        height: 60vh;
      }
      .table-no-data {
        height: 60vh;
      }
      .table-loader {
        height: 60vh;
      }
    }
  }
}
.filter-cnt-bubble {
  background-color: var(--primaryalt);
}

.filter-cnt-badge {
  white-space: nowrap;
  text-align: center;
  line-height: 18px;
  padding: 0 6px;
  height: 18px;
  background: var(--primarycolor);
  position: absolute;
  border-radius: 50%;
  font-size: 12px;
  color: var(--buttontext);
  left: 13px;
  top: -5px;
  font-weight: 600;
}
</style>
<style lang="scss">
@media (max-width: 480px) {
  .image img {
    position: absolute;
    top: 19%;
    left: 21%;
  }
}

.daterangepicker.opensright:before {
  left: 360px !important;
}
.daterangepicker.opensright:after {
  left: 361px !important;
}
.daterangepicker {
  .drp-buttons {
    .btn {
      text-transform: uppercase;
    }
    .applyBtn {
      color: #fff !important;
      background-color: var(--primarycolor) !important;
    }
  }
}
.daterangepicker .drp-buttons .applyBtn:hover,
.daterangepicker .drp-buttons .cancelBtn:hover {
  background-color: #424242 !important;
}
::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: rgba(111, 111, 111, 0.2);
  border: 0px none #ffffff;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: #525965;
}
::-webkit-scrollbar-thumb:active {
  background: #525965;
}
::-webkit-scrollbar-track {
  background: transparent;
  border: 0px none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
  background: transparent;
}
::-webkit-scrollbar-track:active {
  background: transparent;
}
::-webkit-scrollbar-corner {
  background: transparent;
}

.checkbox-btn span {
  margin: 0 40px 0 10px !important;
  font-size: 14px !important;
}

.ddselect {
  width: 200px;
  height: 40px;
  margin-left: 1rem;
  background-color: #474a56;
  border-radius: 4px;
}
.container-chart {
  text-align: center;
  svg {
    display: block;
    margin: auto;
  }
}
.c3 text.c3-empty {
  font-size: 13px;
  fill: #bac0c5 !important;
}
.light-theme {
  .txt-center {
    color: #444;
  }
}
.txt-center {
  position: absolute;
  width: 740px;
  font-size: 13px;
  color: #bac0c5;
}
.txt-align-center {
  display: inline-grid;
  align-items: center;
  width: 100%;
  min-height: 65px;
  font-size: 13px;
  color: #bac0c5;
  text-align: center;
}
.showIncrBox {
  .mid-area-1 {
    //width: 25% !important;
    padding: 20px 15px !important;
    .stat-cnt {
      width: 100%;
      .mid-area-1-cont {
        margin-right: 0.5rem !important;
        width: auto !important;
        span.big {
          font-size: 36px !important;
        }
      }
    }
  }
  .quick-stat:not(:last-child) {
    margin: 0 20px 0 0 !important;
  }
}
.disableFilterPanel {
  pointer-events: none !important;
  opacity: 0.3;
  filter: brightness(0.9);
}
.intermediate-screen-bg {
  background-color: #fff;
  height: calc(100vh - 60px);
}
.readonly-db {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.4;
}
.advance-filter-clear-icon {
  position: relative;
  top: -2px;
  left: 5px;
  cursor: pointer;
  font-size: 13px;
  &:hover {
    color: var(--primarycolor) !important;
  }
}
.dashboard-loading-spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.drp-wrap {
  position: relative;
  display: block;
  padding: unset;
  margin-right: unset;
  font-size: 14px;
  line-height: 1.42857143;
  color: #fff;
  background-color: #2d3138;
  background-image: none;
  border-bottom: 0px;
  border-radius: 4px;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  font-family: sans-serif;
}
.light-theme .drp-wrap {
  background-color: #f9f9f9;
  border: none;
  color: #2f323a;
  border-bottom: 1px;
  text-align: right;
  padding: 6px 12px;
  font-size: 14px;
  > input {
    color: #2f323a !important;
    font-family: Arial, sans-serif;
  }
  .compare-label {
    color: rgba(129, 140, 165, 1);
  }
}
.drp-wrap > input {
  width: 100%;
  min-width: 216px;
  max-width: unset;
  height: auto !important;
  padding: 0 !important;
  margin-bottom: 0;
  font-size: 1.13em;
  line-height: normal;
  color: #fff;
  letter-spacing: 0.03em;
  cursor: pointer;
  background: none !important;
  border: none;
  outline: none;
  box-shadow: none !important;
  font-family: sans-serif;
}

.controls .drp-wrap > input {
  min-height: 27px;
}

.drp-wrap .compare-label {
  bottom: 0px;
  font-size: 75%;
  color: rgba(129, 140, 165, 1);
  text-align: left;
  cursor: pointer;
}

.drp-wrap .compare-label > span {
  margin-left: 5px;

  /* bottom: 0px; */

  /* font-size: 80%; */
  color: rgb(129 140 165);
  cursor: pointer;
}

.drp-wrap {
  background-color: transparent;
  border: 1px solid transparent;
}
</style>
