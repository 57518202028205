<template>
  <div class="bdc_main_wrap whero">
    <div class="thw_content">
      <div class="internal-sub-nav">
        <div
          v-for="(tab, i) in tabOptions"
          :key="i"
          class="isn-item"
          :class="{ active: tab.id === selectedTab, disabled: tab.disabled }"
          @click="onTabClick(tab)"
        >
          {{ tab.value }}
        </div>
      </div>
    </div>
    <div
      v-if="
        tableauJwtToken &&
          (selectedTab === 'publishers' ||
            selectedTab === 'geofence' ||
            selectedTab === 'top-domains')
      "
    >
      <tableau-viz id="tableauViz" :src="tableauViewURL" :token="tableauJwtToken" toolbar="top">
        <custom-parameter name=":showShareOptions" value="false"></custom-parameter>
        <viz-filter field="Start Date" :value="startDate"></viz-filter>
        <viz-filter field="End Date" :value="endDate"></viz-filter>
        <viz-filter field="Advertiser" :value="advertiserName"></viz-filter>
        <viz-filter field="Contract ID" :value="campaigns"></viz-filter>
        <viz-filter field="ConLine ID" :value="adReadyIdList"></viz-filter>
        <viz-filter field="Media Type" :value="mediaTypelist"></viz-filter>
        <viz-filter field="Tier" :value="audiencesList"></viz-filter>
        <viz-parameter field="embed" value="yes"></viz-parameter>
      </tableau-viz>
    </div>
    <div v-else>
      <!-- Component for Campaigns/Ad group table -->
      <div id="performanceAnalysis"></div>
      <div
        class="bdc_panel full-width expan def-box"
        :class="isCampaignTableExpanded ? 'expd' : ''"
      >
        <performance-analysis
          :section-title="selectedTabTitle"
          :tab-options="paTabOptions"
          :meta-data="paMetaData"
          :rows="paRows"
          :loading="paLoading || filtersAppliedLoading"
          :is-table-expanded="isCampaignTableExpanded"
          :sort-by="paSortBy"
          :is-brand-table="true"
          :sort-direction="paSortDirection"
          @on-tab-click="handlePATabClick"
          @on-sort-direction-change="handlePaSortDirectionChange"
          @on-change-sort-by="handleSortByChange"
          @on-table-expand="handleTableExpand"
          @on-export="handleExportClick"
        />
      </div>
      <!-- Component for Metric bar chart -->
      <div class="bdc_panel two-thirds last" style="height: 520px;position: relative;">
        <metric-distribution
          :section-title="selectedTabTitle"
          :tab-options="distTabOptions"
          :hide-dimension-options="true"
          :metric-options="barMetricOptions"
          :rows="barMetricRows"
          :loading="barMetricLoading || filtersAppliedLoading"
          :dimension="selectedTab"
          :dimension-options-obj="tabOptions"
          @on-options-change="handleBarMetricOptionsChange"
          @on-export="handleExportClick"
        />
      </div>
      <!-- Component for Distribution -->
      <div class="bdc_panel one-thirds" style="height: 520px;">
        <distribution
          :tab-options="distTabOptions"
          :hide-dimension-options="true"
          :metric-options="distMetricOptions"
          :rows="distRows"
          :loading="distLoading || filtersAppliedLoading"
          :dimension="selectedTab"
          :dimension-options-obj="tabOptions"
          :donut-size="220"
          donut-component-id="sChartsLargeChart"
          @on-options-change="handleDistOptionsChange"
          @on-export="handleExportClick"
        />
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'underscore';
import { get } from 'vuex-pathify';
import { uuid } from '@/helpers/global/misc-helpers';
import { getCorrectLabels, downloadCSVFile } from '@/util/utility-functions';
import {
  ACCOUNT_SPECIFIC_CAMPAIGN_SUB_TAB_OPTIONS,
  CAMPAIGN_SUB_TAB_OPTIONS,
  DELIVERY_TAB_OPTIONS,
  DISTRIBUTION_MEASUREMENT_OPTIONS,
  DELIVERY_METRIC_OPTIONS,
  PERFORMANCE_ANALYSIS_DIMENSION_COLUMN,
  PERFORMANCE_ANALYSIS_COLUMNS_PER_DIMENSION,
  PERFORMANCE_ANALYSIS_CONVERSIONS_COLUMNS,
  PERFORMANCE_ANALYSIS_REVENUES_COLUMNS,
  PERFORMANCE_ANALYSIS_DIMENSION_TITLES,
  ACCOUNT_SPECIFIC_DELIVERY_TAB_OPTIONS,
  ACCOUNT_SPECIFIC_PERFORMANCE_ANALYSIS_DIMENSION_TITLES,
} from '@/performance-brand-constant';
import axios from 'axios';
import { VIA_ACCOUNT_ID } from '@/constant';
import PerformanceAnalysis from '~/components/performance-analysis.vue';
import Distribution from '~/components/distribution.vue';
import MetricDistribution from '~/components/metric-distribution.vue';

import appConsoleDataLayer from '~/api/app-console-data';
import { formatDateForAPI } from '~/util/apiDateFormat';
import config from '~/config';

export default {
  components: {
    PerformanceAnalysis,
    Distribution,
    MetricDistribution,
  },
  props: {
    activeSolutionId: {
      type: Number,
      required: false,
      default: 1,
    },
    isCampaignTableExpanded: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    let initialDimension = 'campaign';
    const tabOptions = DELIVERY_TAB_OPTIONS;

    let selectedTab = '';
    let selectedTabTitle = '';
    let selectedTabUrl = '';
    if (tabOptions?.length) {
      const defaultSelectedTab = tabOptions.find((t) => t.selected) || this.tabOptions[0];
      selectedTab = defaultSelectedTab?.id || tabOptions[0].id;
      selectedTabTitle = defaultSelectedTab?.value || tabOptions[0].value;
      selectedTabUrl = defaultSelectedTab?.viewUrl || tabOptions[0]?.viewUrl;
      initialDimension = selectedTab;

      if (defaultSelectedTab?.subTabs?.length) {
        const defaultSelectedSubTab = defaultSelectedTab?.subTabs?.find((t) => t.selected);
        initialDimension = defaultSelectedSubTab?.id || defaultSelectedTab?.subTabs[0].id;
      }
    }

    const distMetricOptions = DISTRIBUTION_MEASUREMENT_OPTIONS;
    const selectedDistMetric = distMetricOptions?.length ? distMetricOptions[0].id : '';
    const barMetricOptions = DELIVERY_METRIC_OPTIONS;
    const selectedBarMetric = barMetricOptions?.length ? barMetricOptions[0].id : '';
    return {
      tabOptions,
      selectedTab,
      selectedTabTitle,
      selectedTabUrl,
      tableauJwtToken: null,
      selectedDistDimension: initialDimension,
      selectedDistMetric,
      distRows: [],
      distLoading: false,
      selectedBarDimension: initialDimension,
      selectedBarMetric,
      barMetricRows: [],
      barMetricLoading: false,
      selectedPADimension: initialDimension,
      paRequestId: '',
      paMetaData: [],
      paRows: [],
      paSortBy: '',
      paSortDirection: 'asc',
      paLoading: false,
    };
  },
  computed: {
    account: get('common/account'),
    advertiser: get('common/advertiser'),
    isViaAccount: get('common/isViaAccount'),
    dates: get('dashboard/dates'),
    selectedCampaigns: get('dashboard/GET_SELECTED_CAMPAIGNS'),
    selectedCampaignIds: get('dashboard/GET_SELECTED_CAMPAIGN_IDS'),
    selectedAdGrpOptStr: get('dashboard/GET_SELECTED_AD_GROUP_STR'),
    selectedMediaTypes: get('dashboard/GET_SELECTED_MEDIATYPES'),
    selectedCreatives: get('dashboard/GET_SELECTED_CREATIVES'),
    selectedAudiences: get('dashboard/GET_SELECTED_AUDIENCES'),
    campaignOptions: get('dashboard/filters@campaignOptions'),
    adGroupOptions: get('dashboard/filters@adGroupOptions'),
    mediaTypeOptions: get('dashboard/filters@mediaTypeOptions'),
    audienceOptions: get('dashboard/filters@audienceOptions'),
    pixelOptions: get('dashboard/filters@pixelOptions'),
    selectedPixelIds: get('dashboard/GET_SELECTED_PIXEL_IDS'),
    filtersApplied: get('dashboard/filtersApplied'),
    filtersAppliedLoading: get('dashboard/filtersAppliedLoading'),
    preventDatesLoading: get('dashboard/preventDatesLoading'),
    pixelDropdownOptions() {
      if (!this.pixelOptions) {
        return [];
      }

      if (this.pixelOptions.length === 1) {
        return [];
      }

      return this.pixelOptions;
    },
    distMetricOptions() {
      const restrictedMetric = this.isRestrictedTab();
      let options = JSON.parse(JSON.stringify(DISTRIBUTION_MEASUREMENT_OPTIONS));
      if (restrictedMetric) {
        options = options.filter((option) => option.id !== 'revenue');
      }
      if (this.account.id === VIA_ACCOUNT_ID) {
        options = options.filter((option) => option.id !== 'spend' && option.id !== 'revenue');
      }
      return options;
    },
    barMetricOptions() {
      const restrictedMetric = this.isRestrictedTab();
      if (restrictedMetric || this.account.id === VIA_ACCOUNT_ID) {
        return JSON.parse(
          JSON.stringify(DELIVERY_METRIC_OPTIONS.filter((option) => option.id !== 'roas'))
        );
      }
      return JSON.parse(JSON.stringify(DELIVERY_METRIC_OPTIONS));
    },
    advertiserId() {
      return this.advertiser ? this.advertiser.id : 0;
    },
    advertiserName() {
      return this.advertiser ? this.advertiser.name : '';
    },
    xandrAdvertiserId() {
      return this.advertiser ? this.advertiser.xandrAdvertiserId : 0;
      // return '3884073415';
    },
    payload() {
      return {
        advertiser: this.advertiser ? this.advertiser.name : '',
        client: this.account ? this.account.name : '',
        campaignIds: this.selectedCampaignIds,
        adGroups: this.selectedAdGrpOptStr,
        startDate: formatDateForAPI(this.dates.startDate),
        endDate: formatDateForAPI(this.dates.endDate),
        mediaTypes: this.selectedMediaTypes,
        audiences: this.selectedAudiences,
        creatives: this.selectedCreatives,
        pixelIds: this.selectedPixelIds,
      };
    },
    distTabOptions() {
      if (this.selectedTab === 'campaign_adgroup') {
        return getCorrectLabels(
          CAMPAIGN_SUB_TAB_OPTIONS,
          ACCOUNT_SPECIFIC_CAMPAIGN_SUB_TAB_OPTIONS[this.account.id]
        );
      }
      const currentTab = this.tabOptions.find((t) => t.id === this.selectedTab);
      return currentTab?.subTabs || [];
    },
    tableauViewURL() {
      return `${config.TABLEAU_URL}${this.selectedTabUrl}`;
    },
    startDate() {
      return formatDateForAPI(this.dates.startDate);
    },
    endDate() {
      return formatDateForAPI(this.dates.endDate);
    },
    paTabOptions() {
      const currentTab = this.tabOptions.find((t) => t.id === this.selectedTab);
      return currentTab?.subTabs || [];
    },
    adReadyIdList() {
      const { adGroupOptions } = this;
      if (adGroupOptions.length === 0) {
        return '';
      }
      const selectedAdGroupOptions = adGroupOptions.filter((f) => f.checked);
      // Check if all options are selected
      if (selectedAdGroupOptions.length === adGroupOptions.length) {
        return ''; // All values are selected
      }
      // Gather adreadyIds from the selected options
      let queryStrArray = [];
      selectedAdGroupOptions.forEach((adGroup) => {
        const { adreadyIds } = adGroup;
        adreadyIds.forEach((lid) => {
          queryStrArray.push(lid.adreadyId);
        });
      });
      // Remove duplicates
      queryStrArray = queryStrArray.filter((item, index) => queryStrArray.indexOf(item) === index);
      return queryStrArray.join(',');
    },
    mediaTypelist() {
      return this.selectedMediaTypes.join(',');
    },
    audiencesList() {
      return this.selectedAudiences.join(',');
    },
    campaignIds() {
      if (this.selectedCampaignIds.length) {
        return this.selectedCampaignIds.join(',');
      }
      return '';
    },
    agencyId() {
      return this.account ? this.account.id : 0;
    },
    campaigns() {
      const uncheckedCampaigns = this.campaignOptions.find((item) => item.checked === false);
      if (!uncheckedCampaigns) {
        return '';
      }
      if (this.selectedCampaigns.length) {
        return this.selectedCampaigns.join(',');
      }
      return '';
    },
  },
  watch: {
    advertiser: {
      immediate: false,
      async handler(n, o) {
        if (!_.isEqual(n, o)) {
          this.debounceTableauAuthToken();
          await this.loadAllData();
        }
      },
    },

    dates: {
      immediate: false,
      async handler(n, o) {
        if (!_.isEqual(n, o)) {
          if (!this.preventDatesLoading && !this.filtersAppliedLoading) {
            this.debounceTableauAuthToken();
            await this.loadAllData();
          }
          this.$store.set('dashboard/preventDatesLoading', false);
        }
      },
    },
    filtersApplied: {
      deep: true,
      async handler(n) {
        if (n) {
          this.debounceTableauAuthToken();
          await this.loadAllData();
          // this.$store.set('dashboard/filtersAppliedLoading', false);
        }
      },
    },
  },
  beforeMount() {
    this.tabOptions = getCorrectLabels(
      DELIVERY_TAB_OPTIONS,
      ACCOUNT_SPECIFIC_DELIVERY_TAB_OPTIONS[this.account.id]
    );
  },
  async mounted() {
    if (!this.isViaAccount) {
      this.tabOptions = this.tabOptions.filter(
        (item) => !['publishers', 'geofence', 'top-domains'].includes(item.id)
      );
    }

    const recaptchaScript = document.createElement('script');
    recaptchaScript.setAttribute(
      'src',
      `${config.TABLEAU_URL}/javascripts/api/tableau.embedding.3.latest.js`
    );
    recaptchaScript.setAttribute('type', 'module');
    document.head.appendChild(recaptchaScript);
    this.getTableauAuthToken();
    await this.loadAllData();
  },
  methods: {
    debounceTableauAuthToken() {
      _.debounce(this.getTableauAuthToken(), 100, true);
    },
    async getTableauAuthToken() {
      axios
        .get(`${config.ADREADY_URL}/api/token/tableau`, {
          withCredentials: true,
        })
        .then((d) => {
          this.tableauJwtToken = d.data.result;
        });
    },
    isRestrictedTabOpts() {
      if (this.isRestrictedTab()) {
        this.$store.set('dashboard/isConversionDisabled', true);
      } else {
        this.$store.set('dashboard/isConversionDisabled', false);
      }
    },
    isRestrictedTab() {
      const currentTab = this.tabOptions.find((t) => t.id === this.selectedTab);
      return (
        currentTab.id === 'geo' ||
        currentTab.id === 'device' ||
        currentTab.id === 'top-domains' ||
        currentTab.id === 'publishers' ||
        currentTab.id === 'geofence'
      );
    },
    handlePaSortDirectionChange(direction) {
      this.paSortDirection = direction;
    },
    handleSortByChange(value) {
      this.paSortBy = value;
    },
    handleExportClick(fileName, csvData) {
      try {
        downloadCSVFile(csvData, fileName);
      } catch (err) {
        console.error(`error downloading delivery ${fileName} file ->`, err);
        throw err;
      }
    },
    async loadAllData() {
      if (!this.advertiser) {
        return;
      }
      const apiFuncs = [
        this.loadPerformanceData(),
        this.loadDistributionData(),
        this.loadMetricDistributionData(),
      ];

      // Chain the promises using reduce
      // await Promise.all(apiFuncs);
      await Promise.all(apiFuncs).then(() => {
        this.$store.set('dashboard/filtersApplied', false);
        this.$store.set('dashboard/filtersAppliedLoading', false);
      });

      // await apiFuncs.reduce((chain, apiFunc) => chain.then(apiFunc), Promise.resolve());
    },
    handleTableExpand(val) {
      this.$emit('on-expand-campaign-table', val);
    },
    async loadDistributionData() {
      this.distLoading = true;
      this.distRows = await appConsoleDataLayer.fetchBrandDistributionData(
        this.advertiserId,
        this.activeSolutionId,
        this.selectedDistDimension,
        this.selectedDistMetric,
        this.selectedPixelIds,
        this.payload
      );
      this.distLoading = false;
    },
    async loadMetricDistributionData() {
      this.barMetricLoading = true;
      this.barMetricRows = await appConsoleDataLayer.fetchBrandMetricDistributionData(
        this.advertiserId,
        this.activeSolutionId,
        this.selectedBarDimension,
        this.selectedBarMetric,
        this.selectedPixelIds,
        this.payload
      );
      this.barMetricLoading = false;
    },
    async loadPerformanceData() {
      this.paRequestId = uuid();
      this.paLoading = true;
      this.paMetaData = this.fetchPerformanceMetaData(this.selectedPADimension);
      if (['campaign', 'adgroup'].includes(this.selectedPADimension)) {
        this.paSortBy = 'startDate';
        this.paSortDirection = 'desc';
      } else {
        this.paSortBy = '';
        this.paSortDirection = 'asc';
      }
      const response = await appConsoleDataLayer.fetchBrandDimensionPerformance(
        this.paRequestId,
        this.advertiserId,
        this.activeSolutionId,
        this.selectedPADimension,
        this.payload
      );
      if (this.paRequestId === response.requestId) {
        this.paRows = response.data;
        this.paLoading = false;
        return;
      }
      setTimeout(() => {
        this.paLoading = false;
      }, 200);
    },
    fetchPerformanceMetaData(dimension) {
      const list = [PERFORMANCE_ANALYSIS_DIMENSION_COLUMN];
      // const tabOpts = DELIVERY_TAB_OPTIONS;
      list[0].value =
        getCorrectLabels(
          PERFORMANCE_ANALYSIS_DIMENSION_TITLES,
          ACCOUNT_SPECIFIC_PERFORMANCE_ANALYSIS_DIMENSION_TITLES[this.account.id]
        )[dimension] || 'Name';
      let columns = PERFORMANCE_ANALYSIS_COLUMNS_PER_DIMENSION[dimension];
      const restrictedCol = ['country', 'state', 'dma', 'device'].includes(dimension);
      if (!columns) {
        columns = PERFORMANCE_ANALYSIS_COLUMNS_PER_DIMENSION.other;
      }
      if (this.account.id !== VIA_ACCOUNT_ID) {
        columns = columns.filter((item) => item.key !== 'keyMediaTypeFilter');
      }
      if (this.account.id === VIA_ACCOUNT_ID) {
        columns = columns.filter(
          (item) => item.key !== 'spend' && item.key !== 'actualPacing' && item.key !== 'budget'
        );
      }
      list.push(...columns);
      if (!restrictedCol && !(this.account.id === VIA_ACCOUNT_ID)) {
        list.push(...PERFORMANCE_ANALYSIS_REVENUES_COLUMNS);
      }
      // if (!restrictedCol) {
      //   list.push(...PERFORMANCE_ANALYSIS_REVENUES_COLUMNS);
      // }
      const selectedPixels = this.pixelOptions?.filter((f) => f.checked);
      if (
        ['country', 'state', 'dma', 'device'].includes(dimension) ||
        selectedPixels?.length === 0
      ) {
        list.push(...PERFORMANCE_ANALYSIS_CONVERSIONS_COLUMNS);
      } else {
        // add dynamic columns for each pixel
        selectedPixels.forEach((p) => {
          if (!p.dynamicColumns) {
            return;
          }

          list.push(
            ...p.dynamicColumns.filter(
              (c) => !(c.key.endsWith('_revenue') || c.key.endsWith('_roas'))
            )
          );
        });
      }
      return list;
    },
    onTabClick(tab) {
      if (tab.disabled) {
        return;
      }
      this.selectedTab = tab.id;
      this.selectedTabTitle = tab.value;
      this.selectedTabUrl = tab?.viewUrl;

      let selectedDistTab = '';
      let selectedPATab = tab.id;
      this.debounceTableauAuthToken();
      this.isRestrictedTabOpts();
      if (
        this.selectedTab === 'publishers' ||
        this.selectedTab === 'geofence' ||
        this.selectedTab === 'top-domains'
      )
        return;
      const { distTabOptions, paTabOptions } = this;
      if (distTabOptions?.length) {
        const defaultDistTab = distTabOptions?.find((t) => t.selected) || distTabOptions[0];
        selectedDistTab = defaultDistTab?.id || '';
      }
      if (paTabOptions?.length) {
        const defaultPATab = paTabOptions?.find((t) => t.selected) || paTabOptions[0];
        selectedPATab = defaultPATab?.id || tab.id;
      }
      const currentTab = this.tabOptions.find((t) => t.id === this.selectedTab);
      if (
        (currentTab.id === 'geo' || currentTab.id === 'device') &&
        this.selectedDistMetric === 'revenue'
      ) {
        this.selectedDistMetric = DISTRIBUTION_MEASUREMENT_OPTIONS[0].id;
      }
      this.handlePATabClick(selectedPATab);
      this.handleDistOptionsChange(selectedDistTab, '', this.selectedDistMetric);
      this.handleBarMetricOptionsChange(selectedDistTab, '', this.selectedBarMetric);
    },
    async handleDistOptionsChange(distTab, dimension, metric) {
      this.distLoading = true;
      this.selectedDistMetric = metric;
      if (_.isEmpty(distTab)) {
        if (_.isEmpty(dimension)) {
          this.selectedDistDimension = this.selectedTab;
        } else {
          this.selectedDistDimension = dimension;
        }
      } else {
        this.selectedDistDimension = distTab;
      }
      this.distRows = await appConsoleDataLayer.fetchBrandDistributionData(
        this.advertiserId,
        this.activeSolutionId,
        this.selectedDistDimension,
        this.selectedDistMetric,
        this.selectedPixelIds,
        this.payload
      );
      this.distLoading = false;
    },
    async handleBarMetricOptionsChange(barTab, dimension, metric) {
      this.barMetricLoading = true;
      this.selectedBarMetric = metric;
      if (_.isEmpty(barTab)) {
        if (_.isEmpty(dimension)) {
          this.selectedBarDimension = this.selectedTab;
        } else {
          this.selectedBarDimension = dimension;
        }
      } else {
        this.selectedBarDimension = barTab;
      }

      this.barMetricRows = await appConsoleDataLayer.fetchBrandMetricDistributionData(
        this.advertiserId,
        this.activeSolutionId,
        this.selectedBarDimension,
        this.selectedBarMetric,
        this.selectedPixelIds,
        this.payload
      );
      this.barMetricLoading = false;
    },
    async handlePATabClick(paTab) {
      this.paLoading = true;
      this.selectedPADimension = paTab;
      await this.loadPerformanceData();
    },
  },
};
</script>
<style lang="scss" scoped>
#performanceAnalysis {
  scroll-margin: 75px;
}
.expan {
  &.expd {
    height: calc(100vh - 90px) !important;
    max-height: calc(100vh - 90px) !important;
  }
}
.bdc_main_wrap.whero {
  /* margin-top: 70px; */
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.body-content.fullmax .thw_content {
  max-width: 1600px;
}
.thw_content {
  max-width: 1270px;
  margin: auto;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  padding: 0px;
  position: relative;
  z-index: 11;
}
.sopened .thw_content {
  max-width: 1160px;
}
.internal-sub-nav {
  display: inline-block;
  float: none;
  margin: 11px 20px 31px 5px;
  display: block;
}
.isn-item {
  display: inline-block;
  margin-right: 20px;
  font-size: 14px;
  font-weight: 600;
  color: var(--primarydark2);
  position: relative;
  cursor: pointer;
}

.isn-item.active {
  color: var(--primarydark);
}

.isn-item.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.isn-item.active::before {
  content: '';
  width: 100%;
  height: 2px;
  margin-top: 25px;
  position: absolute;
  background-color: var(--primarycolor);
}

.isn-item:hover {
  color: var(--primarydark);
}

.isn-item:hover::before {
  content: '';
  width: 100%;
  height: 2px;
  margin-top: 25px;
  position: absolute;
  background-color: var(--primarydark2);
}

.bdc_panel {
  padding: 19px 25px 25px 25px;
  border-radius: 10px;
  box-shadow: 0px 10px 30px 0px rgba(0, 40, 60, 0.07);
  box-shadow: 0px 14px 35px -12px rgba(0, 40, 100, 0.17);
  background-color: #fff;
  margin-bottom: 30px;
  border: 1px solid rgb(165 173 178 / 22%);
}
@media (max-width: 900px) {
  .bdc_panel.two-thirds {
    width: 100% !important;
  }
}
.bdc_panel.two-thirds {
  width: calc(65% - 15px);
  display: inline-block;
}
@media (max-width: 900px) {
  .bdc_panel.one-thirds {
    width: 100% !important;
  }
}

.bdc_panel.one-thirds {
  width: calc(35% - 15px);
  display: inline-block;
}

.bdc_panel.last {
  float: right;
}

.bdc_panel .dropdown {
  border: 1px solid #c8d3e4;
}
.height430 {
  height: 430px;
}
.height495 {
  height: 495px;
}
</style>
